<template>
  <f7-page class="orderview-page">
    <template #fixed>
      <ProductNavigationComponent
        :title="$t.getTranslation('LBL_ORDER_INFO')"
        :type="navType"
        :search="false"
        :cart="false"
        :hidechat="true"
        :parentOrderKey="Order.ParentOrderKey"
        :orderKey="Order.OrderKey"
        :invoice="Order?.Payment?.IsSuccess ? true : false"
      />
      <OrderToolbarComponent :order="OrderKey" />
    </template>

    <div class="orderview-container">
      <div class="container">
        <div class="cart-info">
          <div class="content">
            <h1>{{ Order.Ref }}</h1>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_ORDER_STATUS') }}</h3>
            <h3>{{ $t.getTranslation(OrderStatus[Order.OrderStatusCode]) }}</h3>
          </div>
          <div class="content">
            <h3>{{ $t.getTranslation('LBL_DATE_CREATED') }}</h3>
            <h3>{{ helpers.formatDateTime(Order.LastCreated) }}</h3>
          </div>
          <div class="content">
            <h3>{{ $t.getTranslation('LBL_DATE_UPDATED') }}</h3>
            <h3>{{ helpers.formatDateTime(Order.LastModified) }}</h3>
          </div>

          <div class="content" v-if="courier">
            <h3>{{ $t.getTranslation('LBL_CROSS_BORDER_COURIER') }}</h3>
            <h3>{{ courier.CourierCode }}</h3>
          </div>

          <div class="content" v-if="courier">
            <h3>{{ $t.getTranslation('LBL_TRACKING_NUM') }}</h3>
            <h3>{{ courier.CourierTrackingNo }}</h3>
          </div>

          <div class="content" v-if="Order?.Id?.IdNumber">
            <h3>{{ $t.getTranslation('LBL_ID_INFORMATION') }}</h3>
            <h3>{{ Order.Id.FullName }} {{ Order.Id.IdNumber }}</h3>
          </div>

          <div class="content">
            <a href="javascript:;" @click="showSheet"
              ><h3>{{ $t.getTranslation('LBL_SHOW_DELIVERY_ADDRESS') }}</h3></a
            >
          </div>

          <div class="content" v-if="Order.ParentOrderId > 0" style="justify-content: center">
            <h2>{{ $t.getTranslation('LBL_SUB_ORDERS_TO_PAID') }}</h2>
          </div>

          <div class="content" v-if="Order.ParentOrderId > 0 && subOrders.length > 0" style="justify-content: center">
            <h2>{{ subOrderIndex }} / {{ subOrders.length }}</h2>
          </div>
        </div>

        <div class="title">
          <h1>{{ $t.getTranslation('LBL_PRODUCTS') }}</h1>
        </div>

        <div v-for="(product, image) in Order.Items" :key="'pr_' + image" class="product-container">
          <div class="orderproductitem">
            <div class="image" style="width: 100px">
              <div class="prod-image-disp" :style="{ width: '100px', height: '100px', 'background-image': 'url(' + helpers.getImage(product.Product.PrimaryImage, 'PRODUCT') + ')' }"></div>
            </div>
            <div class="content" style="width: calc(100% - 100px)">
              <h3>{{ helpers.getAttribute(product.Product, $t.getLanguage(), 'Name') }}</h3>
              <div v-if="product?.PackingQty?.Total"><font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x {{ product.PackingQty.Total }}</div>

              <h4>{{ $t.getTranslation('LBL_QUANTITY') }}: {{ product.QtyOrdered }}</h4>
              <h4>{{ $t.getTranslation('LBL_TOTAL') }}: {{ helpers.formatPrice(product.UnitPrice, Order.CurrencyCode) }}</h4>

              <div class="btns">
                <f7-button v-if="!product.Reviewed && Order.CanReview" fill style="margin-top: 10px; max-width: 180px; float: right" @click="reviewNow(product)">{{ $t.getTranslation('BTN_REVIEW') }}</f7-button>
                <f7-button fill style="margin-top: 10px; max-width: 180px; float: right" @click="gotoProduct(product.Product)">{{ $t.getTranslation('LBL_VIEW') }}</f7-button>
              </div>
            </div>
          </div>
        </div>

        <div class="title">
          <h1>{{ $t.getTranslation('LBL_ORDER_STATUS') }}</h1>
        </div>

        <div class="timeline">
          <div v-for="(status, ind) in Order.StatusHistory" :key="'stat_' + ind" class="timeline-item">
            <div class="timeline-item-date">
              <h4>{{ helpers.formatDateOrder(status.LastModified).toUpperCase() }}</h4>
              <h5>{{ helpers.formatTime(status.LastModified) }}</h5>
            </div>
            <div class="timeline-item-divider"></div>
            <div class="timeline-item-content">
              <div class="timeline-item-inner">
                <div class="timeline-item-title">{{ $t.getTranslation(OrderStatus[status.OrderStatusCode]) }}</div>
                <div class="timeline-item-subtitle">
                  {{ $t.getTranslation(OrderStatusDescription[status.OrderStatusCode]) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="title">
          <h1>{{ $t.getTranslation('LBL_ORDER_TRANSACTIONS') }}</h1>
        </div>

        <div class="timeline">
          <div v-for="(status, ind) in Order.PaymentHistory" :key="'stat_' + ind" class="timeline-item">
            <div class="timeline-item-date">
              <h4>{{ helpers.formatDateOrder(status.LastModified).toUpperCase() }}</h4>
              <h5>{{ helpers.formatTime(status.LastModified) }}</h5>
            </div>
            <div class="timeline-item-divider"></div>
            <div class="timeline-item-content">
              <div class="timeline-item-inner">
                <div class="timeline-item-title">{{ status.IsSuccess ? $t.getTranslation('LBL_PAYMENT_SUCCESS') : $t.getTranslation('LBL_PAYMENT_FAILED') }}</div>
                <div class="timeline-item-subtitle">{{ status.PaymentDetails }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="title" v-if="Order?.RefundDetails">
          <h1>{{ $t.getTranslation('LBL_REFUND_DETAILS') }}</h1>
        </div>

        <div class="timeline" v-if="Order?.RefundDetails">
          <div v-for="(status, ind) in Order.RefundHistory" :key="'stat_' + ind" class="timeline-item">
            <div class="timeline-item-date">
              <h4>{{ helpers.formatDateOrder(status.LastModified).toUpperCase() }}</h4>
              <h5>{{ helpers.formatTime(status.LastModified) }}</h5>
            </div>
            <div class="timeline-item-divider"></div>
            <div class="timeline-item-content">
              <div class="timeline-item-inner">
                <div class="tim Feline-item-title">{{ $t.getTranslation(OrderStatus[status.OrderStatusCode]) }}</div>
                <div class="timeline-item-subtitle">{{ $t.getTranslation(status.OrderStatusDescription) }} - {{ helpers.formatPrice(status.OrderRefundAmount, Order.CurrencyCode) }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div v-if="Order.Shipment && Order.Shipment.length">

	       <div class="title">
	          <h1>{{ $t.getTranslation("LBL_SHIPPING_STATUS") }}</h1>
	        </div>

	        <div class="cart-info">
	          <div class="content">
	            <h3>{{ $t.getTranslation("LBL_COURIER") }}</h3>
	            <h3>{{ Order.Shipment[0].CourierCode }}</h3>
	          </div>
	          <div class="content">
	            <h3>{{ $t.getTranslation("LBL_DATE_UPDATED") }}</h3>
	            <h3>{{ helpers.formatDateTime(Order.Shipment[Order.Shipment.length - 1].LastCreated) }}</h3>
	          </div>
	        </div>

	        <div class="timeline">

	          <div v-for="(item, index) in Order.Shipment" :key="index" class="timeline-item">
	            <div class="timeline-item-date">
	              <h4>{{ helpers.formatDateOrder(item.LastCreated).toUpperCase() }}</h4>
	              <h5>{{ helpers.formatTime(item.LastCreated) }}</h5>
	            </div>
	            <div class="timeline-item-divider"></div>
	            <div class="timeline-item-content">
	              <div class="timeline-item-inner">
	                <div class="timeline-item-title">{{ item.MilestoneCode }}</div>
	                <div class="timeline-item-subtitle">{{ item.CourierCode }} - {{ item.CourierTrackingNo }}</div>
	              </div>
	            </div>
	          </div>


	        </div>

        </div> -->

        <div v-if="Order.ShipmentTracking && Order.ShipmentTracking.length">
          <div class="title">
            <h1>{{ $t.getTranslation('LBL_SHIPMENT_STATUS_EVENTS') }}</h1>
          </div>

          <div class="timeline">
            <div v-for="(status, ind) in Order.ShipmentTracking" :key="'stat_' + ind" class="timeline-item">
              <div class="timeline-item-date">
                <h4>{{ helpers.formatDateOrder(status.event_date).toUpperCase() }}</h4>
                <h5>{{ helpers.formatTime(status.event_date) }}</h5>
              </div>
              <div class="timeline-item-divider"></div>
              <div class="timeline-item-content">
                <div class="timeline-item-inner">
                  <div class="timeline-item-title" v-if="status.event_title">{{ $t.getTranslation(status.event_title) }}</div>
                  <div class="timeline-item-subtitle">{{ renderTrackingDescription(status, $t.getLanguage()) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cart-info">
          <div class="content">
            <h3>{{ $t.getTranslation('LBL_TOTAL_QUANTITY') }}</h3>
            <h3>{{ Order.TotalItems }} {{ $t.getTranslation('LBL_UNITS') }}</h3>
          </div>
          <div class="content">
            <h3>{{ $t.getTranslation('LBL_TOTAL_BOTTLES') }}</h3>
            <h3>{{ (typeof Order.TotalBottles !== 'undefined' && Order.TotalBottles) || '---' }} {{ $t.getTranslation('LBL_PCS') }}</h3>
          </div>
          <div class="content">
            <h3>{{ $t.getTranslation('LBL_SUB_TOTAL') }}</h3>
            <h3>{{ helpers.formatPrice(Order.SubTotal, Order.CurrencyCode) }}</h3>
          </div>
          <div class="content">
            <h3>{{ $t.getTranslation('LBL_SHIPPING_FEE') }}</h3>
            <h3>{{ helpers.formatPrice(Order.ShippingTotal, Order.CurrencyCode) }}</h3>
          </div>
          <div class="content" v-if="$configs?.isTaxInclusive">
            <h3>{{ $t.getTranslation('LBL_ESTIMATED_ORDER_TAX') }}</h3>
            <h3>{{ helpers.formatPrice(Order.TotalOrderTax, Order.CurrencyCode) }}</h3>
          </div>
          <div class="content" v-if="Order.OrderDiscount">
            <h3>{{ $t.getTranslation('LBL_PLATFORM_DISCOUNT') }}</h3>
            <h3>{{ helpers.formatPrice(Order.OrderDiscount, Order.CurrencyCode) }}</h3>
          </div>
          <div class="content" v-if="Order.UserVoucherAmount">
            <h3>{{ $t.getTranslation('LBL_COUPON') }}</h3>
            <h3>{{ helpers.formatPrice(Order.UserVoucherAmount, Order.CurrencyCode) }}</h3>
          </div>
          <div class="content">
            <h3>{{ $t.getTranslation('LBL_GRAND_TOTAL') }}</h3>
            <h3>{{ helpers.formatPrice(Order.GrandTotal, Order.CurrencyCode) }}</h3>
          </div>
        </div>

        <div v-if="Order.OrderStatusCode == 'INITIATED'">
          <div class="title">
            <h1>{{ $t.getTranslation('LBL_PAYMENT_METHOD') }}</h1>
          </div>

          <ul class="paymentmethods">
            <li v-for="payment in PaymentMethodList" :key="payment.key" class="payment" :class="{ active: PaymentMethod == payment.key }" @click="selectPayment(payment.key)">
              <div class="disp-flex">
                <font-awesome-icon :icon="payment.icon" fixed-width />
                <h1>{{ payment.label }}</h1>
              </div>

              <span v-if="PaymentMethod == payment.key" class="default-payment">
                <font-awesome-icon :icon="['fas', 'check-circle']" fixed-width />
                {{ $t.getTranslation('LBL_SELECTED_PAYMENT') }}
              </span>
            </li>
          </ul>
        </div>

        <!-- banner for order-info -->
        <div class="order-info-banner" v-if="!$configs?.canAddToCart">
          <img class="order-info-img" :src="require('@/assets/images/order-info-bg.png')" loading="lazy" />
          <p class="order-info-text">{{ $t.getTranslation('LBL_GO_TO_PJF') }} <a href="https://pjfwines.com" style="color: #fff">https://pjfwines.com</a> {{ $t.getTranslation('LBL_TO_CHECKOUT_YOUR_ORDER') }}</p>
        </div>

        <div class="button-containers">
          <f7-button v-if="Order.OrderStatusCode == 'INITIATED' && $configs?.canAddToCart" fill large :disabled="!PaymentMethod" @click="paynow">{{ $t.getTranslation('BTN_PAY_NOW') }}</f7-button>

          <!--  <f7-button fill large>{{ $t.getTranslation("LBL_SUBMIT_REVIEW") }}</f7-button>
          <f7-button large>{{ $t.getTranslation("LBL_REQUEST_REFUND") }}</f7-button> -->
        </div>

        <!-- PW-576 NOTICE -->
        <div class="content" style="padding: 20px 5px" v-if="Order.OrderStatusCode == 'INITIATED' && $configs?.canAddToCart" fill large :disabled="!PaymentMethod">
          <h3 style="color: #fff; background: #ffb319; display: flex; align-items: center; padding: 10px 20px; border-top-left-radius: 5px; border-top-right-radius: 5px">{{ $t.getTranslation('LBL_NOTES') }}</h3>
          <div style="background: rgb(255 179 25 / 15%); padding: 20px 20px 10px 20px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
            <p style="font-size: 14px; font-weight: 600; margin-top: 0px">{{ $t.getTranslation('LBL_CHECKOUT_NOTICE') }}</p>
            <ul style="list-style-type: decimal; font-size: 14px; margin-top: 5px; padding-left: 15px; font-weight: 600">
              <li>{{ $t.getTranslation('LBL_CHECKOUT_NOTICE_ONE') }}</li>
              <li>{{ $t.getTranslation('LBL_CHECKOUT_NOTICE_TWO') }}</li>
              <li>{{ $t.getTranslation('LBL_CHECKOUT_NOTICE_THREE') }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <OrderRateServiceComponent ref="orderRateServiceComponent" />

    <f7-sheet class="address-sheet" swipe-to-close backdrop>
      <f7-page-content>
        <div class="share-container">
          <div class="title">
            <h1>{{ $t.getTranslation('LBL_DELIVERY_ADDRESS') }}</h1>
          </div>
          <div class="order-delivery-address-cont">
            <h3>{{ Order?.Address?.LastName }}{{ Order?.Address?.FirstName }}</h3>
            <p style="margin: 0px">
              <strong>{{ $t.getTranslation('LBL_MOBILE_NUMBER') }}</strong
              >: {{ Order?.Address?.MobileCode }}{{ Order?.Address?.MobileNumber }}
            </p>
            <p style="margin: 0px">
              <strong>{{ $t.getTranslation('LBL_ADDRESS') }}</strong
              >: {{ Order?.Address?.PrimaryAddress }}, {{ Order?.Address?.District }}, {{ Order?.Address?.City }}, {{ Order?.Address?.Province }}
            </p>
          </div>
        </div>
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>

<script>
import { configs, extendedConfigs } from '@/utils/configs'
import { defineComponent, ref, computed, onMounted, inject, defineAsyncComponent } from 'vue'
import { post, get } from '@/utils/axios'
import _ from 'lodash'
import { f7 } from 'framework7-vue'
import { helpers } from '@/utils/helpers.js'
import { useStore } from '@/store'
import { processPayment } from '@/logics/payment'

// import ProductNavigationComponent from '@/components/navigations/ProductNavigationComponent.vue'
// import OrderToolbarComponent from '@/components/navigations/OrderToolbarComponent.vue'
// import OrderRateServiceComponent from '@/components/OrderRateServiceComponent.vue'

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProductNavigationComponent.vue'))
const OrderToolbarComponent = defineAsyncComponent(() => import(/* webpackChunkName: "order-toolbar" */ /* webpackMode: "lazy" */ '@/components/navigations/OrderToolbarComponent.vue'))
const OrderRateServiceComponent = defineAsyncComponent(() => import(/* webpackChunkName: "prder-rate-service" */ /* webpackMode: "lazy" */ '@/components/OrderRateServiceComponent.vue'))

export default defineComponent({
  name: 'OrderViewPage',
  components: {
    OrderToolbarComponent,
    ProductNavigationComponent,
    OrderRateServiceComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $f7router = f7.views.get('#main-view').router
    const PaymentMethodListRaw = ref([])

    const PaymentMethodList = computed(() => {
      let isMini = helpers.isMiniProgram() || helpers.isInsideWeixin()
      return _.filter(PaymentMethodListRaw.value || [], (r) => {
        return (isMini && r.key == 'WeChatPay_Acc') || !isMini
      })
    })

    const PaymentMethod = ref(null)
    const $t = inject('$translation')
    const subOrders = ref([])
    const subOrderIndex = ref(0)
    const courier = ref(null)
    const store = useStore()

    const OrderStatus = ref({
      INITIATED: 'LBL_ORDER_INITIATED',
      CONFIRMED: 'LBL_ORDER_CONFIRMED',
      CLOSED: 'LBL_ORDER_CLOSED',
      PAID: 'LBL_ORDER_PAID',
      UNPAID: 'LBL_ORDER_CLOSED',
      CANCELLED: 'LBL_ORDER_CLOSED',
      PARTIALLY_PAID: 'LBL_PARTIALLY_PAID',
      REFUNDED: 'LBL_ORDER_REFUNDED'
    })

    const OrderStatusDescription = ref({
      INITIATED: 'LBL_INITIATED_SUB_DESCRIPTION',
      CONFIRMED: 'LBL_CONFIRMED_SUB_DESCRIPTION',
      CLOSED: 'LBL_CLOSED_SUB_DESCRIPTION',
      CANCELLED: 'LBL_CLOSED_SUB_DESCRIPTION',
      PAID: 'LBL_PAID_SUB_DESCRIPTION',
      UNPAID: 'LBL_UNPAID_SUB_DESCRIPTION',
      PARTIALLY_PAID: 'LBL_PARTIALLY_PAID_SUB_DESCRIPTION',
      SHIPPED: 'LBL_SHIPPED_SUB_DESCRIPTION',
      REFUNDED: ''
    })

    let OrderKey = ref(props.f7route?.params?.code)
    let Order = ref({
      PaymentHistory: [],
      RefundHistory: []
    })
    let navType = ref('order')

    if (props.f7route?.query?.back) {
      navType.value = 'back'
    }

    if (props.f7route?.query?.callback) {
      navType.value = 'callback'
    }

    if (props.f7route?.query?.mini) {
      navType.value = 'mini'
    }

    const reviewNow = (prod) => {
      $f7router.navigate({ name: 'orderReviewPage', params: { order: OrderKey.value, product: prod.Product.ProductKey } })
    }

    const goToSubOrders = (ParentOrderKey) => {
      return $f7router.navigate({ name: 'profileSubOrderPage', query: { ParentOrderKey: ParentOrderKey } })
    }

    const getSubOrders = async (ParentOrderKey) => {
      let obj = {
        ParentOrderKey: ParentOrderKey
      }
      let ret = await get('/order/list', obj)
      subOrders.value = _.orderBy(ret.List, ['OrderId'], ['asc'])
      let index = _.findIndex(
        subOrders.value,
        (e) => {
          if (e.OrderKey == props.f7route?.params?.code) {
            return e
          }
        },
        0
      )
      index = index + 1
      subOrderIndex.value = index
    }

    const paynow = async () => {
      if (!PaymentMethod.value) return
      await processPayment(PaymentMethod.value, OrderKey.value)
    }

    onMounted(async () => {
      if (!props.f7route?.params?.code) return
      let ret = await get('/order/view', { key: props.f7route.params.code })
      if (!ret) return props.f7router.back({ force: true })

      let isWechat = helpers.isInsideWeixin()
      if (ret.PaymentMethod && ret.PaymentMethod.length && isWechat) {
        ret.PaymentMethod = _.filter(ret.PaymentMethod, (r) => {
          return r.key != 'Alipay_Wap'
        })
      }

      PaymentMethodListRaw.value = ret.PaymentMethod || []
      if (ret?.Order.ParentOrderKey) {
        await getSubOrders(ret.Order.ParentOrderKey)
      }

      if (ret.Order.Shipment.length > 0) {
        let pick = {}
        let pickTracking = _.pick(ret.Order.Shipment[0], ['CourierCode', 'CourierTrackingNo'])
        pick['CourierCode'] = pickTracking.CourierCode
        pick['CourierTrackingNo'] = pickTracking.CourierTrackingNo
        courier.value = pick
      }

      if (ret?.Order) {
        let retIdNumber = await get('/user/idnumber', {}, true)
        ret.Order.Id = {}
        if (retIdNumber) {
          ret.Order.Id = { ...ret.Order.Id, ...retIdNumber }
        }
      }

      if (ret?.Order?.Refund?.RawData) {
        let rawData = ret?.Order?.Refund?.RawData
        rawData = rawData.replace(/\//g, '')
        rawData = JSON.parse(rawData)
        let pickRefund = _.pick(rawData, ['account', 'terminal', 'payment_datetime', 'push_datetime', 'order_currency', 'order_amount', 'payment_id', 'refund_number', 'refund_reference', 'push_id', 'push_details'])
        let orderdetails = {
          OrderNumber: ret.Order.Refund.PaymentOrderNumber
        }
        pickRefund = { ...pickRefund, ...orderdetails }
        let history = {
          LastModified: pickRefund.push_datetime,
          OrderStatusCode: 'REFUNDED',
          OrderStatusDescription: 'LBL_ORDER_REFUNDED_AMOUNT',
          OrderRefundAmount: pickRefund.order_amount
        }
        ret.Order.RefundHistory = []
        ret.Order.RefundHistory.push(history)
        ret.Order.RefundDetails = pickRefund
      }

      Order.value = ret.Order
    })

    const selectPayment = (method) => {
      PaymentMethod.value = method
    }

    const gotoProduct = (product) => {
      if (product?.ProductKey) {
        return $f7router.navigate({ name: 'productViewPage', params: { code: product?.ProductKey } })
      }
    }

    const renderTrackingDescription = (status, lang) => {
      return lang == 'EN' ? status.event_desc_en || status.event_desc_cn : status.event_desc_cn
    }

    const showSheet = () => {
      f7.sheet.get('.address-sheet').open()
    }

    return {
      showSheet,
      OrderKey,
      renderTrackingDescription,
      selectPayment,
      PaymentMethodList,
      PaymentMethod,
      paynow,
      navType,
      helpers,
      Order,
      reviewNow,
      OrderStatus,
      OrderStatusDescription,
      gotoProduct,
      subOrders,
      goToSubOrders,
      subOrderIndex,
      courier
    }
  },
  async mounted() {
    let returnData = await get(`/platform/review/check?OrderKey=${this.OrderKey}`)
    if (returnData && returnData.showRatePlatform) this?.$refs?.orderRateServiceComponent?.showRateServiceSheet()
  }
})
</script>

<style>
.order-info-text {
  position: absolute;
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 0 30px;
}
.order-info-img {
  position: relative;
  width: 380px;
  padding: 20px 0;
}
.order-info-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.btns > a {
  margin: 0px 5px 5px;
}

.order-delivery-address-cont {
  padding: 15px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}

.orderproductitem {
  cursor: pointer;
}

.suborder-badge {
  height: 22px;
  width: 22px;
  background-color: #ff3b30;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: #fff;
}
</style>
